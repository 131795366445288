<template>
  <div>
    <Card>
      <template slot="outer">
        <PageTopOuter
          icon="widgets"
          :search-box="false"
          label="Blog"
          @on-create="onCreateNew"
        />
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-md-center justify-sm-center justify-xs-center">
          <div class="inline-flex flex-wrap justify-sm-center justify-xs-center">
            <SelectComponent class="w-64 m-2" />
            <SelectComponent class="w-64 m-2" />
          </div>
          <SearchBox placeholder="search" class="w-64 p-2 m-2" />
        </div>
        <md-divider></md-divider>
        <Table :items="blogs" @onSelect="onSelect">
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-label="BLOG_ID" class="text-victoria">{{ item.blogId }}</md-table-cell>
            <md-table-cell md-label="BLOG INDEX" class="text-victoria">{{ item.blogIndex }}</md-table-cell>
            <md-table-cell md-label="BLOG ON">{{ item.blogOn }}</md-table-cell>
            <md-table-cell md-label="CREATED BY" class="text-victoria">{{ item.createdBy }}</md-table-cell>
            <md-table-cell md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
            <md-table-cell md-label="GROUP FOR">{{ item.groupFor }}</md-table-cell>
            <md-table-cell md-label="COMMENTS">{{ item.comments }}</md-table-cell>
            <md-table-cell md-label="STATUS" class="text-victoria">{{ item.status }}</md-table-cell>
          </template>
        </Table>
      </CardBody>
    </Card>
    <!-- Dialog Start -->
    <Dialog >
        <components :content="content" :is="component"></components>
    </Dialog>
    <!-- Dialog Start -->
  </div>
</template>

<script>
import { Card, CardBody, SelectComponent, Dialog, Table, PageTopOuter } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import TheBlogCreate from "@/components/molecule/blog/TheBlogCreate"
import ShowDetails from "@/components/molecule/blog/ShowDetails"
import data from "@/data/blog/data"
import { mapMutations } from "vuex";

export default {
  name: "Blog-List-View",
  components: {
    Card,
    Table,
    Dialog,
    CardBody,
    SearchBox,
    ShowDetails,
    PageTopOuter,
    TheBlogCreate,
    SelectComponent
  },
  data() {
    return {
      component: 'TheBlogCreate',
      content: {},
      blogs: data.blogs
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onCreateNew() {
      this.component = 'TheBlogCreate'
    },
    onSelect(item) {
      this.component = 'ShowDetails';
      this.content = item;
      this.dialog(true);
    }
  },
};
</script>