<template>
    <div class="p-6" style="width: 650px">
        <DialogTitle style="margin-left: -10px; margin-right: -10px" label="How to be Expert In Listening" align="center" />
        <Content class="my-3" label="Blog ID" text="content.blogId" />
        <div>
             <p class="text-victoria text-uppercase mb-3">Blog Thumbnail</p>
             <img src="/images/blog-thumbnail.png" alt="">
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
            <Content class="my-3" label="Branch" text="Dhanmondi" text-color="text-capitalize" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
            <Content class="my-3" label="Related To" text="Listening" text-color="text-capitalize" />
            </div>
        </div>
        <Content class="my-3" label="Group" :text="content.groupFor" />
        <Content class="my-3" label="Blog Link" text="https://apical.com/xseseef" text-color="text-victoria text-capitalize"/>
        <Content class="my-3" label="Status" text="Active" text-color="text-green" />
        <div class="flex justify-center">
            <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
            <md-button class="bg-victoria text-white text-uppercase rounded" >Go To Edit Page</md-button>
        </div>
    </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    Content
  },
  props: {
      content: {
          type: Object
      }
  },
  methods: mapMutations({
      dialog: "setShowDialog",
  })
}
</script>

