
const blogs = [];

for (let i = 0; i < 15; i++) {
    blogs.push({
        id: i,
        blogId: 'B125'+i,
        blogIndex: 'Importance of APICAL',
        blogOn: 'Speaking',
        createdBy: 'Kazi Shahin',
        createdAt: '23 Sep, 2020, 9:00am',
        groupFor: 'Group A, Group C',
        comments: 15,
        status: 'Active'
    });
}


export default {
    blogs
}