<template>
  <form @submit.prevent="onSubmit" class="p-6">
    <DialogTitle bg-color="bg-victoria" label="Create New Blog" align="center" />
    <div class="md-layout md-gutter mt-6">
      <div class="md-layout-item md-size-75 mb-3">
        <InputFieldComponent
          itali
          label="Blog Header"
          v-model.trim="$v.form.header.$model"
          :message="!$v.form.header.required && $v.form.header.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-40 mb-3">
        <SelectComponent
          label="Choose Branch"
          italic
          class="w-full"
          v-model.trim="$v.form.branchId.$model"
          :message="!$v.form.branchId.required && $v.form.branchId.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-25 mb-3">
        <SelectComponent
          label="Choose Group"
          italic class="w-full"
          v-model.trim="$v.form.groupId.$model"
          :message="!$v.form.groupId.required && $v.form.groupId.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-40 mb-3">
        <SelectComponent
          label="Choose Related to"
          italic
          class="w-full"
          v-model.trim="$v.form.relatedId.$model"
          :message="!$v.form.relatedId.required && $v.form.relatedId.$dirty ? 'Field is required' : null"
          />
      </div>
    </div>
    <div class="md-layout md-gutter mb-3 mt-6">
      <div class="md-layout-item">
        <FileInputCompoment
          italic
          label="Tags"
          v-model.trim="$v.form.tags.$model"
          :message="!$v.form.tags.required && $v.form.tags.$dirty ? 'Field is required' : null"
          />
      </div>

      <div class="md-layout-item">
        <FileInputCompoment
          italic
          label="Choose thumbnail"
          v-model.trim="$v.form.thumbnail.$model"
          :message="!$v.form.thumbnail.required && $v.form.thumbnail.$dirty ? 'Field is required' : null"
        />
      </div>
    </div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-75 mb-3">
        <InputFieldComponent
          italic
          label="Copy Blog Link"
          v-model.trim="$v.form.blogLink.$model"
          :message="!$v.form.blogLink.required && $v.form.blogLink.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-100 my-6">
        <label class="text-uppercase font-bold text-italic"> Comment Accebility </label>
        <RadioButtonComponent
            :items="[{id:1, label: 'Enable'}, {id:2, label: 'Disable'}]"
            v-model.trim="$v.form.commentable.$model"
            :message="!$v.form.commentable.required && $v.form.commentable.$dirty ? 'Field is required' : null"
          />
      </div>
      <div class="flex justify-end align-center md-layout-item md-size-100 mb-3">
         <md-button class="md-default">Save As Draft</md-button>
         <md-button class="border border-solid border-gray-500 rounded">Schedule</md-button>
         <md-button type="submit" class="bg-victoria text-white">Create</md-button>
      </div>
    </div>
  </form>
</template>

<script>
import { 
  SelectComponent,
  InputFieldComponent,
  DialogTitle,
  RadioButtonComponent
  } from "@/components";
import FileInputCompoment from "@/components/atom/form/FileInputComponent";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    SelectComponent,
    FileInputCompoment,
    InputFieldComponent,
    RadioButtonComponent
  },
  data() {
    return {
      radio: true,
      form: {
        header: "",
        branchId: "",
        groupId: "",
        relatedId: "",
        tags: "",
        thumbnail: "",
        blogLink: "",
        commentable: ""
      }
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    onSubmit() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar('Blog saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    }
  },
  validations: {
    form: {
      header: {required},
      branchId: {required},
      groupId: {required},
      relatedId: {required},
      tags: {required},
      thumbnail: {required},
      blogLink: {required},
      commentable: {required}
    }
  }
};
</script>

<style></style>
